import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";

import lc from "./localeContent";
import SortableExpressionNode from "./SortableExpressionNode";

function ExpressionOperators(props) {
	const { operatorOptions, addItemToEndOfDropzone } = props;

	return (
		<Box
			sx={{
				mb: 2.5,
				display: "flex",
				alignItems: "center"
			}}
		>
			<Box>
				<Typography
					sx={{
						ml: 0.5 // Add margin to align with field enable switch
					}}
				>
					{lc.EXPRESSION_OPERATORS_LABEL}:
				</Typography>
			</Box>
			<Box
				sx={{
					listStyle: "none",
					display: "flex",
					p: 0,
					ml: 2
				}}
			>
				{operatorOptions.map(comparatorOption => {
					const { sortableId, label } = comparatorOption;
					return (
						<SortableExpressionNode
							key={sortableId}
							sortableId={sortableId}
							label={label}
							chipVariant="outlined"
							isLeafNode={false}
							expressionChipSx={{ my: 0.5, mx: 1 }}
							onAdd={() => {
								addItemToEndOfDropzone(comparatorOption);
							}}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

ExpressionOperators.propTypes = {
	operatorOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
	addItemToEndOfDropzone: PropTypes.func.isRequired
};

export default ExpressionOperators;
