import { subDays, subMonths, endOfMonth, startOfMonth } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const yesterdayInTimezone = timeZone => {
	const yesterdayUtc = subDays(new Date(), 1);
	return utcToZonedTime(yesterdayUtc, timeZone);
};

// TODO: CP-5780 Not sure this function does what we expect it to do. The new Date() we are passing here is not guaranteed to be in UTC if the code is running in a different timezone
export const todayInTimezone = timeZone => {
	return utcToZonedTime(new Date(), timeZone);
};

export const DATE_RANGE = {
	today: "today",
	yesterday: "yesterday",
	previous7: "previous7",
	previous30: "previous30",
	monthToDate: "monthToDate",
	previousMonth: "previousMonth"
};

export const getDateRangeByTimezone = (dateRangeType, timezone) => {
	const currentDate = todayInTimezone(timezone);
	const yesterday = subDays(currentDate, 1);
	let formattedDate = { startDate: "", endDate: "" };
	switch (dateRangeType) {
		case DATE_RANGE.today:
			formattedDate.startDate = currentDate;
			formattedDate.endDate = currentDate;
			break;
		case DATE_RANGE.yesterday:
			formattedDate.startDate = yesterday;
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.previous7:
			formattedDate.startDate = subDays(yesterday, 6);
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.previous30:
			formattedDate.startDate = subDays(yesterday, 29);
			formattedDate.endDate = yesterday;
			break;
		case DATE_RANGE.monthToDate:
			formattedDate.startDate = startOfMonth(currentDate);
			formattedDate.endDate = currentDate;
			break;
		case DATE_RANGE.previousMonth:
			formattedDate.startDate = startOfMonth(subMonths(currentDate, 1));
			formattedDate.endDate = endOfMonth(subMonths(currentDate, 1));
			break;
		default:
			formattedDate = {};
			break;
	}
	return formattedDate;
};

export default {
	yesterdayInTimezone
};
