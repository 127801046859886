import mapEnumToFieldOptions from "../utils/mapEnumToOptions";
import { DASHBOARD_API_URL } from "./ApiUrlConfig";

export const NAV_RAIL_WIDTH = 73;
export const MUI_GRID_CONTAINER_SPACING = 2;
export const MAXIMUM_DB_VALUE = 2147483647;
export const MUI_PADDING_NORMAL = "normal";

export const MEDIA_TYPE_VIDEO_ID = 2;
export const MEDIA_TYPE_DISPLAY_ID = 6;
export const MEDIA_SOURCE_ASSET_ID = "asset";
export const CREATIVE_TYPE_MEDIA_URL = "mediaUrl";
export const COMPANY_ID_QUERY_PARAM = "companyId";
export const FROM_QUERY_PARAM = "from";

export const EVENT_ENTER_KEY = "Enter";

export const SECONDARY_COLORS = {
	DEEP_BLUE: {
		id: "#0745ff",
		name: "Deep Blue"
	},
	AMBER: {
		id: "#f37f21",
		name: "Amber"
	},
	BROWN: {
		id: "#8b6e60",
		name: "Brown"
	},
	BLUE_GREY: {
		id: "#486c79",
		name: "Blue Grey"
	},
	RED: {
		id: "#d41900",
		name: "Red"
	},
	LIGHT_BLUE: {
		id: "#22cbff",
		name: "Light Blue"
	},
	LIME: {
		id: "#89b73a",
		name: "Lime"
	},
	PURPLE1: {
		id: "#af4cac",
		name: "Purple1"
	},
	BLUE1: {
		id: "#03a9f4",
		name: "Blue1"
	},
	ORANGE: {
		id: "#b5a33f",
		name: "Orange"
	},
	DEEP_ORANGE: {
		id: "#f44f03",
		name: "Deep Orange"
	},
	DEEP_PURPLE1: {
		id: "#824ac3",
		name: "Deep Purple1"
	},
	PURPLE2: {
		id: "#4939dc",
		name: "Purple2"
	},
	BLUE2: {
		id: "#0066ff",
		name: "Blue2"
	},
	GREEN1: {
		id: "#00b461",
		name: "Green1"
	},
	GREEN2: {
		id: "#3cb027",
		name: "Green2"
	},
	TURQUOISE: {
		id: "#00b8d1",
		name: "Turquoise"
	},
	MAROON: {
		id: "#96000f",
		name: "Maroon"
	},
	BLUE3: {
		id: "#b4fff",
		name: "Blue3"
	},
	GREY: {
		id: "#757575",
		name: "Grey"
	}
};

export const PRIMARY_COLORS = {
	RED: {
		id: "red",
		name: "Red",
		secondary: SECONDARY_COLORS.TURQUOISE
	},
	PINK: {
		id: "pink",
		name: "Pink",
		secondary: SECONDARY_COLORS.GREEN1
	},
	PURPLE: {
		id: "purple",
		name: "Purple",
		secondary: SECONDARY_COLORS.GREEN2
	},
	DEEP_PURPLE: {
		id: "deepPurple",
		name: "Deep Purple",
		secondary: SECONDARY_COLORS.LIME
	},
	INDIGO: {
		id: "indigo",
		name: "Indigo",
		secondary: SECONDARY_COLORS.ORANGE
	},
	BLUE: {
		id: "blue",
		name: "Blue",
		secondary: SECONDARY_COLORS.AMBER
	},
	LIGHT_BLUE: {
		id: "lightBlue",
		name: "Light Blue",
		secondary: SECONDARY_COLORS.DEEP_ORANGE
	},
	CYAN: {
		id: "cyan",
		name: "Cyan",
		secondary: SECONDARY_COLORS.RED
	},
	TEAL: {
		id: "teal",
		name: "Teal",
		secondary: SECONDARY_COLORS.MAROON
	},
	GREEN: {
		id: "green",
		name: "Green",
		secondary: SECONDARY_COLORS.PURPLE1
	},
	LIGHT_GREEN: {
		id: "lightGreen",
		name: "Light Green",
		secondary: SECONDARY_COLORS.DEEP_PURPLE1
	},
	LIME: {
		id: "lime",
		name: "Lime",
		secondary: SECONDARY_COLORS.PURPLE2
	},
	YELLOW: {
		id: "yellow",
		name: "Yellow",
		secondary: SECONDARY_COLORS.BLUE2
	},
	AMBER: {
		id: "amber",
		name: "Amber",
		secondary: SECONDARY_COLORS.DEEP_BLUE
	},
	ORANGE: {
		id: "orange",
		name: "Orange",
		secondary: SECONDARY_COLORS.BLUE1
	},
	DEEP_ORANGE: {
		id: "deepOrange",
		name: "Deep Orange",
		secondary: SECONDARY_COLORS.LIGHT_BLUE
	},
	BROWN: {
		id: "brown",
		name: "Brown",
		secondary: SECONDARY_COLORS.BLUE_GREY
	},
	GREY: {
		id: "grey",
		name: "Grey",
		secondary: SECONDARY_COLORS.BLUE1
	},
	BLUE_GREY: {
		id: "blueGrey",
		name: "Blue Grey",
		secondary: SECONDARY_COLORS.BROWN
	}
};

export const BREADCRUMBS_ENUM = {
	ADVERTISER: {
		key: "advName",
		responseKeyName: "advName",
		responseKeyId: "advId",
		baseRoute: "advertisers"
	},
	INSERTION_ORDER: {
		key: "insertionOrderName",
		responseKeyName: "insertionOrderName",
		responseKeyId: "insertionOrderId",
		baseRoute: "insertion-orders"
	},
	NAME: {
		key: "name",
		responseKeyName: "name"
	},
	CAMPAIGN: {
		key: "campaignName",
		responseKeyName: "campaignName",
		responseKeyId: "campaignId",
		baseRoute: "campaigns"
	},
	PUBLISHER: {
		key: "publisherName",
		responseKeyName: "publisherName",
		responseKeyId: "publisherId",
		baseRoute: "publishers"
	},
	SITES: {
		key: "siteName",
		responseKeyName: "siteName",
		responseKeyId: "siteId",
		baseRoute: "sites"
	}
};

export const REPORT_TYPE_OPTIONS_ENUM = {
	network: { value: "network", label: "Network" },
	campaign: { value: "campaign", label: "Campaign" },
	rtb: { value: "rtb", label: "RTB" }
};
export const REPORT_TYPE_OPTIONS = mapEnumToFieldOptions(
	REPORT_TYPE_OPTIONS_ENUM
);

export const TIME_ZONE = {
	AMERICA_LOS_ANGELES: "America/Los_Angeles"
};

export const UTC_TIME_ZONE = "Z";

export const PROTECTED_CONTENT_PATH = "/dashboard";

const HOME_PAGE = process.env.REACT_APP_HOME_PAGE_OVERRIDE || "reporting";

export const HOME_PAGE_PATH = `${PROTECTED_CONTENT_PATH}/${HOME_PAGE}`;

export const getHomePageFullPath = companyId =>
	`${HOME_PAGE_PATH}?${COMPANY_ID_QUERY_PARAM}=${companyId}`;

export const FILTER_TYPE = {
	ALPHA_MULTIPLE: "alpha_multiple",
	ALPHA_SINGLE: "alpha_single",
	CURRENCY: "currency",
	PERCENTAGE: "percent"
};

export const TABLE_DATA_TYPE = {
	CURRENCY: "currency",
	PERCENTAGE: "percent",
	NUMBER: "number",
	STATUS: "alpha_single"
};

export const SERVER_URL_WITH_VERSION = `${DASHBOARD_API_URL}/v2`;
export const COMPANY_LIST_ID = {
	column6CompanyId: 1
};
export default {
	NAV_RAIL_WIDTH,
	MUI_GRID_CONTAINER_SPACING
};

export const OPEN_URL_TARGET = {
	NEW_TAB: "_blank",
	SAME_TAB: "_self"
};
export const HELP_URL =
	"https://beezag.jira.com/wiki/spaces/CPHC/pages/2971566081/Table+of+Contents";

export const dateRangeServerFormat = "yyyyMMdd";
export const dateTimeServerFormat = "yyyyMMdd HH:mm:ss";
export const defaultCompanyId = 1;
export const AUCTION_TYPE_AGREED_UPON_DEAL_PRICE = "AGREED_UPON_DEAL_PRICE";
export const DEFAULT_BID_REQUEST_MULTIPLIER = 0.000002;
export const DEFAULT_OPPORTUNITY_COST_MULTIPLIER = 0.000002;

export const EXPRESSION_NODE_TYPE = {
	SEGMENT: "SEGMENT",
	AUDIENCE: "AUDIENCE",
	CUSTOM_URL_PARAM: "CUSTOM_URL_PARAM",
	OR: "OR",
	AND: "AND",
	NOT: "NOT",
	NULL: "NULL",
	PARENTHESIS: "PARENTHESIS",
	LEFT_PAREN: "LEFT_PAREN",
	RIGHT_PAREN: "RIGHT_PAREN"
};

export const DENSITY_TYPES = {
	COMPACT: "compact",
	STANDARD: "standard",
	COMFORTABLE: "comfortable"
};
