import React from "react";
import PropTypes from "prop-types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import ExpressionNode from "./ExpressionNode";

function SortableExpressionNode(props) {
	const {
		sortableId,
		label,
		onDelete,
		onDeleteIcon,
		onEdit,
		onAdd,
		size,
		dataTestId,
		expressionChipSx,
		tooltip,
		isInvalid,
		isLeafNode
	} = props;

	const {
		attributes,
		listeners,
		isDragging,
		setNodeRef,
		transform,
		transition
	} = useSortable({ id: sortableId });

	const style = {
		opacity: isDragging ? 0.5 : undefined,
		transform: CSS.Translate.toString(transform),
		transition
	};

	return (
		<ExpressionNode
			ref={setNodeRef}
			style={style}
			label={label}
			attributes={attributes}
			listeners={listeners}
			onDelete={onDelete}
			onDeleteIcon={onDeleteIcon}
			onEdit={onEdit}
			isLeafNode={isLeafNode}
			size={size}
			expressionChipSx={expressionChipSx}
			dataTestId={dataTestId}
			tooltip={tooltip}
			isInvalid={isInvalid}
			onAdd={onAdd}
		/>
	);
}

SortableExpressionNode.propTypes = {
	sortableId: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func,
	onDeleteIcon: PropTypes.node,
	onEdit: PropTypes.func,
	onAdd: PropTypes.func,
	size: PropTypes.string,
	expressionChipSx: PropTypes.shape(),
	dataTestId: PropTypes.string,
	tooltip: PropTypes.string,
	isInvalid: PropTypes.bool,
	isLeafNode: PropTypes.bool
};

SortableExpressionNode.defaultProps = {
	onDelete: undefined,
	onDeleteIcon: undefined,
	onEdit: undefined,
	onAdd: undefined,
	size: undefined,
	expressionChipSx: undefined,
	dataTestId: undefined,
	tooltip: undefined,
	isInvalid: false,
	isLeafNode: false
};

export default SortableExpressionNode;
