import { EXPRESSION_NODE_TYPE } from "../../config/constants";
import lc from "./localeContent";

export default {
	AND: { nodeType: EXPRESSION_NODE_TYPE.AND, label: lc.OPERATOR_LABELS.AND },
	OR: { nodeType: EXPRESSION_NODE_TYPE.OR, label: lc.OPERATOR_LABELS.OR },
	NOT: { nodeType: EXPRESSION_NODE_TYPE.NOT, label: lc.OPERATOR_LABELS.NOT },
	PARENTHESIS: {
		nodeType: EXPRESSION_NODE_TYPE.PARENTHESIS,
		label: lc.OPERATOR_LABELS.PARENTHESIS
	},
	LEFT_PAREN: {
		nodeType: EXPRESSION_NODE_TYPE.LEFT_PAREN,
		label: lc.OPERATOR_LABELS.LEFT_PAREN
	},
	RIGHT_PAREN: {
		nodeType: EXPRESSION_NODE_TYPE.RIGHT_PAREN,
		label: lc.OPERATOR_LABELS.RIGHT_PAREN
	}
};
