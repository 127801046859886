import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSortable } from "@dnd-kit/sortable";
import CheckIcon from "@mui/icons-material/Check";

import SortableExpressionNode from "./SortableExpressionNode";
import {
	SORTABLE_ID_KEY,
	DROPZONE_SORTABLE_ID,
	EXPRESSION_CHIP_TEST_ID
} from "./constants";
import Operators from "./Operators";

function ExpressionDropzone(props) {
	const {
		expression,
		blockErrors,
		removeExpressionNode,
		leafExpressionNodeRepresentation,
		emptyExpressionWarning,
		findContainer,
		showError,
		isActiveItem,
		onEdit,
		activeParenthesisGroups,
		saveActiveParenthesisGroup
	} = props;

	const { setNodeRef, over } = useSortable({
		id: DROPZONE_SORTABLE_ID
	});
	const blockErrorMapByIndex = useMemo(
		() =>
			blockErrors.reduce(
				(agg, el, index) => ({
					...agg,
					[index]: el
				}),
				{}
			),
		[blockErrors]
	);

	const emptyExpression = expression.length === 0;
	const overContainer = findContainer(over?.id);

	return (
		<Paper
			variant="outlined"
			sx={theme => ({
				minHeight: "200px",
				boxShadow: "inset 1px 1px 2px rgba(0, 0, 0, 0.3)",
				p: 0.5,
				...(emptyExpression
					? {
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
					  }
					: {}),
				...(overContainer === DROPZONE_SORTABLE_ID
					? {
							boxShadow: `inset 0px 0px 5px ${theme.palette.primary.main}`,
							borderColor: "primary.main"
					  }
					: {}),
				...(showError
					? {
							boxShadow: `inset 0px 0px 5px ${theme.palette.error.main}`,
							borderColor: "error.main"
					  }
					: {})
			})}
			ref={setNodeRef}
		>
			{expression.map((nodeData, index) => {
				const {
					[SORTABLE_ID_KEY]: sortableId,
					[leafExpressionNodeRepresentation.labelKey]: leafLabel, // Leaves use different keys for their labels
					label, // Operators are always labeled by the "label" key
					nodeType
				} = nodeData;
				const blockError = blockErrorMapByIndex[index];
				const isLeafNode =
					nodeType === leafExpressionNodeRepresentation.nodeType;
				let onDeleteFunction = removeExpressionNode(sortableId);
				let onDeleteIcon;
				// If the block is a parenthesis block and we have active parens, we need to check if the block is active
				if (
					activeParenthesisGroups.length &&
					[
						Operators.LEFT_PAREN.nodeType,
						Operators.RIGHT_PAREN.nodeType
					].includes(nodeType)
				) {
					const isPrimaryActiveRightParenthesis =
						sortableId === activeParenthesisGroups[0].right;
					// The active right parenthesis overrides the "onDelete" function to set the group as not active
					if (isPrimaryActiveRightParenthesis) {
						onDeleteFunction = saveActiveParenthesisGroup;
						onDeleteIcon = <CheckIcon />;
					} else {
						// Any other active pathesis groups will disable the "onDelete" function
						const isActiveParenthesis = activeParenthesisGroups.some(
							({ left, right }) => left === sortableId || right === sortableId
						);
						if (isActiveParenthesis) {
							onDeleteFunction = undefined;
						}
					}
				}
				return (
					<SortableExpressionNode
						key={sortableId}
						sortableId={sortableId}
						label={label || leafLabel}
						onDelete={onDeleteFunction}
						onDeleteIcon={onDeleteIcon}
						onEdit={isLeafNode && onEdit ? onEdit(nodeData) : undefined}
						isLeafNode={isLeafNode}
						isInvalid={showError && Boolean(blockError) && !isActiveItem}
						dataTestId={EXPRESSION_CHIP_TEST_ID}
						tooltip={blockError || undefined}
						expressionChipSx={{
							m: 0.5
						}}
					/>
				);
			})}
			{emptyExpression && (
				<Typography
					sx={{
						py: 1,
						px: 2,
						border: "1px dashed",
						borderColor: "grey.500",
						borderRadius: 4
					}}
				>
					{emptyExpressionWarning}
				</Typography>
			)}
		</Paper>
	);
}

ExpressionDropzone.propTypes = {
	expression: PropTypes.arrayOf(PropTypes.shape()),
	blockErrors: PropTypes.arrayOf(PropTypes.string),
	removeExpressionNode: PropTypes.func.isRequired,
	onEdit: PropTypes.func,
	leafExpressionNodeRepresentation: PropTypes.shape({
		nodeType: PropTypes.string.isRequired,
		labelKey: PropTypes.string
	}).isRequired,
	emptyExpressionWarning: PropTypes.string.isRequired,
	findContainer: PropTypes.func.isRequired,
	showError: PropTypes.bool,
	isActiveItem: PropTypes.bool,
	activeParenthesisGroups: PropTypes.arrayOf(
		PropTypes.shape({ left: PropTypes.string, right: PropTypes.string })
	).isRequired,
	saveActiveParenthesisGroup: PropTypes.func.isRequired
};

ExpressionDropzone.defaultProps = {
	expression: [],
	blockErrors: [],
	showError: false,
	isActiveItem: false,
	onEdit: undefined
};

export default ExpressionDropzone;
