import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo
} from "react";
import { Container } from "@mui/material";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
import * as Yup from "yup";
import { stringify } from "qs";
import isEmpty from "lodash/isEmpty";

import lc from "./localeContent";
import ReportingTable from "./ReportingTable/ReportingTable";
import PageTitleBar from "../PageTitleBar/PageTitleBar";
import SubmitButtonWithDirtyIndicator from "../SubmitButtonWithDirtyIndicator/SubmitButtonWithDirtyIndicator";
import {
	REPORT_TYPE_KEY,
	SLICE_CONFIG_KEY,
	DATE_RANGE_KEY,
	FILTER_KEY,
	CHART_CONFIG,
	TIME_ZONE_KEY
} from "./constants";
import { yesterdayInTimezone } from "../../utils/dateUtils";
import { Operations, useResourceAsync } from "../../hooks/useResourceAsync";
import { AuthenticatedUserSettingsContext } from "../../context/AuthenticatedUserSettingsContext";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import { COMPANY_ID_QUERY_PARAM } from "../../config/constants";
import FilterReports from "../FilterReports/FilterReports";
import DateRangeFilter from "./DateRangeFilter";
import KPIMetrics from "./KPIMetrics/KPIMetrics";
import ReportingPageError from "./ReportingPageError";
import { defaultAlphaMultipleSchema } from "../FiltersToolbar/FilterForm";
import ReportingChart from "./ReportingChart/ReportingChart";
import { AuthorizationContext } from "../../context/AuthorizationContext";
import useParsedQueryParams from "../../hooks/useParsedQueryParams";
import { AuthContext } from "../../context/AuthContext";
import { LEFT_AXIS_ID, RIGHT_AXIS_ID } from "./ReportingChart/BasicLineChart";
import { METRIC_TYPES } from "./metricsConfig";
import TimeZoneFilter from "./TimeZoneFilter";
import {
	buildGroupandFilterRequestParams,
	buildPerformanceDataRequestParams,
	getComparisonDateRange
} from "./reportingUtils";

const isGroupAndFilter = sliceConfig => !isEmpty(sliceConfig);

const filterAuthorizedTabs = (tabItem, authorizationContext) => {
	if (tabItem.permissionRequired) {
		return authorizationContext.hasPermission(tabItem.permissionRequired);
	}
	return true;
};

const TABS_CONFIG = [
	{
		key: "network",
		label: lc.TAB_LABEL_NETWORK,
		permissionRequired: "VIEW_NETWORK_REPORT"
	},
	{
		key: "campaign",
		label: lc.TAB_LABEL_CAMPAIGN,
		permissionRequired: "VIEW_CAMPAIGN_REPORT"
	},
	{
		key: "rtb",
		label: lc.TAB_LABEL_RTB,
		permissionRequired: "VIEW_RTB_REPORT"
	}
];

const DEFAULT_FORM_VALUES = {
	[REPORT_TYPE_KEY]: null,
	[DATE_RANGE_KEY]: [null, null],
	[SLICE_CONFIG_KEY]: [],
	[FILTER_KEY]: [],
	[TIME_ZONE_KEY]: null
};

const ALLOWED_COMPARATORS = ["=", "!="];

const getDefaultDateRange = userTimeZone => {
	const yesterdayInUserTimezone = yesterdayInTimezone(userTimeZone);
	return [yesterdayInUserTimezone, yesterdayInUserTimezone];
};

function Reporting() {
	const [activeReportConfig, setActiveReportConfig] = useState(null);
	const [tableData, setTableData] = useState([]);
	const authorizationContext = useContext(AuthorizationContext);
	const {
		userTimeZone,
		isFetchingUserSettings,
		defaultUserMetrics,
		defaultUserDimensions
	} = useContext(AuthenticatedUserSettingsContext);
	const [isApiError, setIsApiError] = useState(false);
	const history = useHistory();
	const browserLocationParams = useParsedQueryParams();

	const [dateRangeDetails] = useState({ type: "" });

	const allowedTabItems = useMemo(() => {
		return TABS_CONFIG.filter(tabItem =>
			filterAuthorizedTabs(tabItem, authorizationContext)
		);
	}, [authorizationContext]);

	const updateActiveReportConfig = useCallback(
		newActiveReportConfig => {
			setActiveReportConfig(newActiveReportConfig);
			history.replace(`reporting?${stringify(newActiveReportConfig)}`);
		},
		[setActiveReportConfig, history]
	);

	// Setup Performance endpoint
	const {
		data: performanceData,
		execute: requestPerformanceData,
		isLoading: isLoadingPerformanceData
	} = useResourceAsync("manage/metrics/performance", Operations.LIST, {
		preserveDataOnExecute: true
	});

	const {
		execute: requestComparisonData,
		data: comparisonData
	} = useResourceAsync("manage/metrics/performance", Operations.LIST);

	const { execute: requestLastProcessedDate } = useResourceAsync(
		"manage/query-metadata/max-processed-time",
		Operations.LIST
	);

	// Setup Group and filter endpoint
	const {
		execute: requestGroupedData,
		isLoading: isLoadingGroupedData
	} = useResourceAsync("manage/metrics/filter-and-group", Operations.LIST);

	const [totalKPI, setTotalKPI] = useState({});
	const [prevKPIInfo, setPrevKPIInfo] = useState({ totals: {} });
	const comparisonDayDifference = useMemo(() => {
		return prevKPIInfo.diffInDays;
	}, [prevKPIInfo]);

	const refreshComparisonData = useCallback(
		async ({ requestParams }) => {
			const {
				start,
				end,
				diffInDays,
				exactTime,
				useHourlyOverride,
				isError
			} = await getComparisonDateRange(
				requestParams,
				dateRangeDetails.type,
				requestLastProcessedDate
			);
			if (!isError) {
				const { data } = await requestComparisonData({
					requestParams: {
						...requestParams,
						start,
						end,
						exactTime,
						useHourlyOverride
					}
				});
				if (!isEmpty(data) && data.totals) {
					setPrevKPIInfo({
						totals: data.totals,
						diffInDays,
						dateRangeType: dateRangeDetails.type
					});
				}
			}
		},
		[dateRangeDetails, requestComparisonData, requestLastProcessedDate]
	);

	// Used to request data at component init and in the Formik onSubmit when users click run report
	const refreshPerformanceData = useCallback(
		async reportConfig => {
			const { [SLICE_CONFIG_KEY]: sliceConfig } = reportConfig;

			const performanceParams = buildPerformanceDataRequestParams(reportConfig);

			setPrevKPIInfo({ totals: {} });

			if (isGroupAndFilter(sliceConfig)) {
				Promise.all([
					requestPerformanceData(performanceParams),
					requestGroupedData(buildGroupandFilterRequestParams(reportConfig))
				]).then(apiResponses => {
					const hasNoError = !apiResponses.some(response => response.error);
					if (hasNoError) {
						// Set active report config
						updateActiveReportConfig(reportConfig);

						refreshComparisonData(performanceParams);
						// Handle the performance data
						const performanceResponseData = apiResponses[0].data;
						setTotalKPI(performanceResponseData.totals);

						// Handle the filter and group data
						const filterAndGroupResponseData = apiResponses[1].data;
						setTableData(filterAndGroupResponseData.data || []);
					} else {
						setIsApiError(true);
					}
				});
			} else {
				const { data } = await requestPerformanceData(performanceParams);
				if (data) {
					setIsApiError(false);
					// Set active report config
					updateActiveReportConfig(reportConfig);
					const { totals } = data;
					refreshComparisonData(performanceParams);
					setTotalKPI(totals);
					setTableData([{ data: totals }]);
				} else {
					setIsApiError(true);
				}
			}
		},
		// Handle errors https://beezag.jira.com/browse/CP-2141
		[
			requestPerformanceData,
			requestGroupedData,
			refreshComparisonData,
			updateActiveReportConfig
		]
	);
	const authContext = useContext(AuthContext);
	const { companyId } = authContext;

	// Wait for user permissions and settings before making the first default request
	const [initialized, setInitialized] = useState(false);
	useEffect(() => {
		if (!initialized && !isEmpty(allowedTabItems) && !isFetchingUserSettings) {
			setInitialized(true);
			// Extract the report config keys from location params
			const reportTypeParam = browserLocationParams[REPORT_TYPE_KEY];
			const dateRangeParam = browserLocationParams[DATE_RANGE_KEY];
			const filterParam = browserLocationParams[FILTER_KEY];
			const sliceConfigParam = browserLocationParams[SLICE_CONFIG_KEY];
			const companyConfParam = browserLocationParams[COMPANY_ID_QUERY_PARAM];
			const timeZone = browserLocationParams[TIME_ZONE_KEY] || userTimeZone;

			// Only allow access to allowed report types via params
			const allowedReportTypeFromParam = allowedTabItems.find(
				({ key }) => key === reportTypeParam
			)?.key;

			const reportConfig = {
				[REPORT_TYPE_KEY]: allowedReportTypeFromParam || allowedTabItems[0].key,
				[DATE_RANGE_KEY]: dateRangeParam
					? dateRangeParam.map(dateString => parseISO(dateString)) // Dates are serialized in params so must be parsed again into a date object
					: getDefaultDateRange(timeZone),
				[SLICE_CONFIG_KEY]: sliceConfigParam || [],
				[FILTER_KEY]: filterParam || [],
				[COMPANY_ID_QUERY_PARAM]: companyId || companyConfParam,
				[TIME_ZONE_KEY]: timeZone
			};
			refreshPerformanceData(reportConfig);
		}
	}, [
		allowedTabItems,
		initialized,
		userTimeZone,
		isFetchingUserSettings,
		refreshPerformanceData,
		browserLocationParams,
		companyId
	]);

	const handleTabChange = async (event, newReportType) => {
		const reportConfig = {
			...activeReportConfig,
			[REPORT_TYPE_KEY]: newReportType,
			[SLICE_CONFIG_KEY]: [],
			[FILTER_KEY]: []
		};
		updateActiveReportConfig(reportConfig);

		refreshPerformanceData(reportConfig);
	};

	const { [REPORT_TYPE_KEY]: activeReportType } = activeReportConfig || {};

	// Setup charts
	const seriesDefaultData = activeReportType
		? [
				{ ...CHART_CONFIG[activeReportType], yAxisId: LEFT_AXIS_ID },
				{
					label: lc.METRIC_LABELS.IMPRESSIONS,
					metric: "impressions",
					type: METRIC_TYPES.NUMBER,
					yAxisId: RIGHT_AXIS_ID
				}
		  ]
		: [];
	const defaultDimensions = useMemo(() => {
		if (browserLocationParams[SLICE_CONFIG_KEY]) {
			return { [activeReportType]: browserLocationParams[SLICE_CONFIG_KEY] };
		}
		return defaultUserDimensions;
	}, [activeReportType, browserLocationParams, defaultUserDimensions]);

	return (
		<>
			<LoadingBackdrop
				isOpen={isLoadingPerformanceData || isLoadingGroupedData}
			/>
			<Formik
				enableReinitialize
				initialValues={activeReportConfig || DEFAULT_FORM_VALUES}
				onSubmit={values => {
					refreshPerformanceData({ ...values });
				}}
			>
				{() => (
					<Form noValidate>
						<PageTitleBar
							barTitle={lc.PAGE_HEADING}
							actionButtonText={lc.RUN_REPORT_BUTTON}
							actionButtonType="submit"
							tabsConfig={allowedTabItems.length > 1 ? allowedTabItems : null}
							activeTabKey={activeReportType}
							handleTabChange={handleTabChange}
							actionButtonComponent={
								<SubmitButtonWithDirtyIndicator
									actionButtonText={lc.RUN_REPORT_BUTTON}
								/>
							}
						/>
						<FilterReports
							name={FILTER_KEY}
							resetDimensionsOnReportTypeChange
							fixedFilters={[
								<TimeZoneFilter key="timeZoneFilter" />,

								<DateRangeFilter
									key="dateRangeFilter"
									onDateSelection={type => {
										// didn't use set state method as it was hitting perf calls on date selection
										dateRangeDetails.type = type;
									}}
								/>
							]}
							filterValidationSchemaOptions={{
								alphaMultipleSchema: defaultAlphaMultipleSchema.concat(
									Yup.array().max(40, lc.MAX_FILTER_VALUES_WARNING)
								)
							}}
							pageTitleBarHasTabs={allowedTabItems.length > 1}
							allowedComparators={ALLOWED_COMPARATORS}
						/>
						{!isApiError && (
							<>
								<KPIMetrics kpiInfo={totalKPI} prevKpiInfo={prevKPIInfo} />
								<Container maxWidth={false}>
									{activeReportType && (
										<ReportingChart
											performanceData={performanceData}
											comparisonData={comparisonData}
											isLoadingPerformanceData={isLoadingPerformanceData}
											activeReportConfig={activeReportConfig}
											series={seriesDefaultData}
											comparisonDayDifference={comparisonDayDifference}
										/>
									)}
									{activeReportConfig && (
										<ReportingTable
											rawData={tableData}
											requestGroupedData={requestGroupedData}
											activeReportConfig={activeReportConfig}
											defaultUserMetrics={defaultUserMetrics}
											defaultUserDimensions={defaultDimensions}
										/>
									)}
								</Container>
							</>
						)}
						{isApiError && <ReportingPageError />}
					</Form>
				)}
			</Formik>
		</>
	);
}

export default Reporting;
